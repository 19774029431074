.container {
    position:absolute;
    width: 90%;
    max-width: 350px;
    height:220px;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    .inner {
        position: relative;
        width: 100%;
        height: 100%;
        .arrow {
            position: absolute;
            width: 140px;
            height: 80px;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            cursor: pointer;
            transition: background 0.2s ease;
            &:hover {
                background: rgba(255, 255, 255, 0.6);
            }
            div {
                position: relative;
                width: 100%;
                height: 100%;
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-image: url(../../assets/images/next.png);
                    background-size: 30px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            &.up {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                div:after {
                    transform: rotate(-90deg);
                }
            }
            &.right {
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &.down {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                div:after {
                    transform: rotate(90deg);
                }
            }
            &.left {
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                div:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}