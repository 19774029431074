.field {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(16px * 30);
    align-self: center;
    margin: 24px auto;
    position: relative;
    transition: filter 1s ease;
    &.is3d {
        transform: perspective(400px) rotateX(20deg);
        margin-top: -20px;
        max-width: calc(24px * 30);
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/images/giphy.gif);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.25;
        }
    }
    &.gameOver {
        filter: blur(4px) opacity(50%);
    }
}

@media screen and (max-width: 820px) {
    .field {
        max-width: calc(12px * 30);
        margin: 48px auto;
        &.is3d {
            max-width: calc(12px * 30);
        }
    }
}