.controls {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 720px) {
    .controls {
        padding: 12px;
    }
}