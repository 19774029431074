.container {
    .score {
        color: #fff;
        font-size: 28px;
    }
}

@media screen and (max-width: 720px) {
    .container {
        .score {
            font-size: 18px;
        }
    }
}