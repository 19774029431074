@import "./variables.scss";
@import "./animations.scss";

html,
body,
#root {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}

.app {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #2b53af 0%, #6733bc 70%);
    display: flex;
    flex-direction: column;
    position: relative;
}

.link {
    /* position: fixed;
    right: 48px;
    top: 24px; */
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    margin-left: auto;
    &:hover {
        opacity: 0.8;
    }
}

.settings {
    width: 36px;
    height: 36px;
    padding: 8px;
    background-image: url(../assets/images/settings.png);
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 16px;
    cursor: pointer;
    &:hover {
        background-color: #03e9f4;
        box-shadow: 0 0 5px #03e9f4, 0 0 5px #03e9f4, 0 0 5px #03e9f4,
            0 0 50px #03e9f4;
        -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
        animation: none;
    }
}

.controls-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 720px) {
    .controls-row {
        justify-content: center;
    }
    .settings {
        margin-right: 4px;
        width: 28px;
        height: 28px;
        background-size: 24px;
    }
}
