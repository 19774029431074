@keyframes neon-sign {
    0%,
    30% {
        color: hsla(183, 100%, 50%, 1);
        text-shadow: 0 0 0.05em hsla(183, 100%, 50%, 0.9),
            0 0 0.15em hsla(183, 100%, 50%, 0.7),
            0 0 0.3em hsla(183, 100%, 50%, 0.5);
    }

    50%,
    90% {
        color: hsla(183, 100%, 50%, 1);
        text-shadow: none;
    }
}
